<template>
    <div>
        <el-tooltip enterable v-if="text != null && text != '' && text.length > 16" class="item" effect="dark"
            :content="text" placement="top">
            <el-button>{{ text.slice(0, 14) + '...' }}</el-button>
        </el-tooltip>
        <p v-else>{{ text }}</p>
    </div>
</template>

<script>
export default {
    name: '',
    props: {
        text: {
            type: [String],
            default: '',
        }
    },
    created() { },
    mounted() {

    },
    data() {
        return {}
    },
    methods: {},
}
</script>

<style lang=scss scoped>
p {
    font-size: 1.22rem;
    color: #000;
    text-align: center;
}

.orver_h3 {
    font-weight: bold;
}

::v-deep .el-button {
    margin: 0;
    padding: 0;
    border: 0;
    background: none;
    // width: 12rem;
    height: 1.3rem;
    white-space: normal;
    overflow: hidden; //超出隐藏，其余内容不可见
    text-overflow: ellipsis; //文本溢出后用省略号显示
    color: #000;
    border: 0 !important;
    text-align: center;

    span {
        display: block;
        font-size: 1.22rem;
        color: #000;
        text-align: center;

    }
}
</style>