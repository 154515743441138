<template>
  <div>
    <!-- 导航 -->
    <!-- <Nav></Nav> -->
    <!-- <input type="input"> -->
    <!-- {{ this.$route }} -->
    <router-view v-if="isRouterAlive" :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: '',
  components: {
    // 导航
    // Nav,
    // 底部
    // Buttom
  },
  computed:{
    ...mapState(['loginShow'])
  },
  created() { },
  mounted() {
    // 检测设备类型
    // const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    // 判断设备类型并重定向到移动端页面
    // if (isMobileDevice) {
    // return window.location.href = 'https://www.baidu.com/';
    // }
    // console.log(this.$route);
    if (localStorage.getItem('signTime')) {
      const currentTime = new Date().getTime(); // 当前时间的时间戳
      const signTime = localStorage.getItem('signTime')
      const storedTimeNumber = Number(signTime); //转为number
      const twoDaysInMilliseconds = 2 * 24 * 60 * 60 * 1000; // 两天的毫秒数

      if (currentTime > (storedTimeNumber + twoDaysInMilliseconds)) {
        this.loginShow = true
        localStorage.clear()
        sessionStorage.clear()
        this.$store.state.loginShow = true
      }
    }
  },
  data() {
    return {
      isRouterAlive: true,
    }
  },
  methods: {
  },
  destroyed() {
    window.removeEventListener('beforeunload')
    window.removeEventListener('unload')
  }
}
</script>
<style lang=scss>
/* // @media screen and (min-width:1200px)and (max-width: 1300px) {
//   * {
//     margin: 0;
//     padding: 0;
//     font-size: 16px;
//     font-family: '微软雅黑';
//   }
// } */

// @media screen and (min-width:960px) and (max-width:1200px){
* {
  margin: 0;
  padding: 0;
  // font-size: 12px;
  font-family: '微软雅黑';
}

html {
  font-size: 12px;
}

// @media screen and (min-width: 1550px) {
//   html {
//     font-size: 12px;
//   }
// }

// @media screen and (max-width: 1550px) {
//   html {
//     font-size: 10px;
//   }
// }

.noneBox {
  height: 80px;
  // background: #026bbc;
}

.box {
  position: relative;
  // z-index: 998;
}

.message_poup_index {
  z-index: 9999 !important;
}


ul {
  list-style: none;
}

.flex {
  display: flex;
}



tr {
  border-bottom: 0.02rem solid #e6e9f4 !important;

  &:last-child {
    border: 0;
  }
}

tbody {
  tr {
    &:last-child {
      border: 0 !important;
    }
  }
}

</style>